import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicYourSteps = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicYourSteps {
      allPrismicYoursteps(filter: {tags: {in: "your_step"}}) {
        nodes {
          lang
          data {
            subtitle {
                text
            }
            yoursteps {
              cardnumber
              mainbutton
              mainbuttonlink
              description {
                richText
              }
              image {
                url
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicYoursteps.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        subTitle:response.subtitle.text,
        yourSteps: response.yoursteps.map(yourStep => ({
            cardNumber: yourStep.cardnumber,
            description: yourStep.description.richText,
            mainButton: yourStep.mainbutton,
            mainButtonLink: yourStep.mainbuttonlink,
            title: yourStep.title.text,
            image: yourStep.image.url,
        }))
    }
}

export default usePrismicYourSteps;
import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { Box, Flex, Heading, Container, Button, Image } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper";
import Left from "../../assets/arrow.svg";
import Right from "../../assets/right.svg";

const w = typeof window !== "undefined" && window.innerWidth;

const Home = ({ data }) => {
  return (
    <Box mb="6rem">
      <Container px="5" maxW="5xl" mt="4rem">
        <Heading
          fontFamily="Mardoto"
          as="h2"
          fontSize={{
            base: "2xl",
            md: "3xl",
          }}
          textAlign="center"
          mb="3rem"
        >
          {data.title}
        </Heading>
      </Container>
      <Box bg="#FBFBFB">
        <Container px="5" pt={{ base: "5", sm: 0 }} maxW="5xl">
          <Box
            display={{
              base: "block",
              md: "none",
            }}
          >
            {data.services.map((loan) => (
              <Flex
                key={`loan-${loan.header}`}
                as={Link}
                to={loan.link}
                alignItems="center"
                direction={{
                  base: "column",
                  md: "row",
                }}
              >
                <Image
                  height="auto"
                  width="auto"
                  maxHeight="200px"
                  objectFit="cover"
                  alt="About us"
                  src={loan.icon}
                />
                <Heading
                  fontFamily="Mardoto"
                  textAlign={{
                    base: "left",
                    sm: "center",
                  }}
                  as="h2"
                  fontSize="lg"
                  mt={{
                    base: 0,
                    sm: "1.375rem",
                  }}
                >
                  {loan.header}
                </Heading>
              </Flex>
            ))}
          </Box>
          <Box display="none">
            <Swiper
              modules={[Navigation, Scrollbar, A11y]}
              navigation={{
                nextEl: ".right",
                prevEl: ".left",
              }}
            >
              <Flex
                w="full"
                direction="row"
                py="2.125rem"
                alignItems={{
                  base: "flex-start",
                  md: "center",
                }}
                justifyContent="center"
                mb="4"
              >
                <Box mr="9" className="left" as={Button}>
                  <Left />
                </Box>
                <Box ml="9" className="right" as={Button}>
                  <Right />
                </Box>
              </Flex>
            </Swiper>
          </Box>
          <Box
            display={{
              base: "none",
              sm: "block",
            }}
          >
            <Swiper
              modules={[Navigation, Scrollbar, A11y]}
              slidesPerView={w < 481 ? 2 : w < 750 ? 3 : w < 900 ? 4 : 5}
              navigation={{
                nextEl: ".right",
                prevEl: ".left",
              }}
            >
              {data.services.map((loan) => (
                <SwiperSlide key={loan.header}>
                  <Flex
                    as={Link}
                    to={loan.link}
                    alignItems="center"
                    direction="column"
                    _hover={{
                      color:"green.100",
                      transform: "scale(1.04)",
                      transition: "transform 0.3s ease,  color 0.3s ease" 
                    }}
                  >
                    <Image  objectFit="cover" alt="About us" src={loan.icon} />
                    <Heading
                      fontFamily="Mardoto"
                      textAlign="center"
                      as="h2"
                      fontSize="md"
                      mt="1.375rem"
                      
                    >
                      {loan.header}
                    </Heading>
                  </Flex>
                </SwiperSlide>
              ))}
              <Flex
                w="full"
                direction="row"
                py="2.125rem"
                alignItems={{
                  base: "flex-start",
                  md: "center",
                }}
                justifyContent="center"
                mb="4"
              >
                <Box mr="9" className="left" as={Button}>
                  <Left />
                </Box>
                <Box ml="9" className="right" as={Button}>
                  <Right />
                </Box>
              </Flex>
            </Swiper>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
export default Home;

import React, { useState } from "react";
import { Box, Flex, Text, Heading, Button, Container, Image } from "@chakra-ui/react";
import Play from '../../assets/play.svg'
import videoLines from '../../images/video_lines.png'
import RichText from "../PrismicRichText";
const Pics = ({ data }) => {
  const [play, setPlay] = useState(false)
  const url = play ? '?autoplay=1&controls=1&enablejsapi=1&rel=0' : '?controls=0'
  return (
    <Flex zIndex="1" py={{base: 0, sm: "3.5rem", xl: '0'}} bg="#FBFBFB" mb={{base: '10.25rem', sm: "6.25rem"}} w="full" position="relative">
      <Container
        px="30px"
        zIndex="10"
        maxW="7xl">
        <Flex
          height="100%"
          direction={{ base: "column", lg: "row" }}
          alignItems="center">
            {
              play ? (
                <Box
                  as="iframe"
                  maxW="732px"
                  w="full"
                  h={{base: '194px', sm: "410px"}}
                  objectFit="cover"
                  src={`${data.aboutVideoLink}${url}`}
                  allow='autoplay; encrypted-media'
                  borderRadius="16px"/>
              ) : (
                <Flex
                  h={{base: '194px', sm: "410px"}}
                  bgImage={`url(${data.aboutVideoImageLink})`}
                  bgSize="cover"
                  bgPos="center"
                  bgRepeat="no-repeat"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="16px"
                  width="100%"
                >
                  <Box
                    cursor="pointer"
                    onClick={() => setPlay(true)}
                    p="5"
                    backgroundColor="rgba(0, 0, 0, .4)"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="100px"
                    width="80px"
                    height="80px"
                  >
                    <Play width="27px" />
                  </Box>
                </Flex>
              )
            }
          <Flex
            flexDirection="column"
            justifyContent="center"
            height={{base: '100%', xl: "522px" }}
            // bg="#3AA835"
            borderRadius={{md:"32px 0 0 32px"}}
            minW="200px"
            w="full"
            mt={{base:"6", lg: '0'}}
            ml={{base: '0', lg: "2.815rem"}}
            pl="8"
            maxW="350px">
            <Heading color="White" fontSize={{base: '2xl', sm: "28px"}} mb="1rem">
                {data.aboutTitle}
            </Heading>
            <Box fontSize="18px" color="white" opacity="0.6" mb="2.25rem">
              <RichText text={data.aboutDescription}/>
            </Box>
            <Button onClick={() => setPlay(true)} variant="darkGreen" w="150px" color="white">
              {data.aboutButtonText}
            </Button>
          </Flex>
        </Flex>
      </Container>
      <Flex
        mt={{base: '6rem', sm: '0'}}
        maxW={{base: "723px", xl: '47%'}}
        zIndex="5"
        bg="#3AA835"
        h="full"
        w="full"
        right="0"
        top="0"
        borderRadius={{md:"32px 0 0 32px"}}
        alignItems="center"
        justifyContent="center"
        position="absolute"
      >
        <Image
          position="absolute"
          left="0"
          bottom="0"
          width="500px"
          zIndex="100"
          src={videoLines}
          />
      </Flex>
    </Flex>
  );
};
export default Pics;

import React from "react";
import { Box, Stack, Heading, Button, Container, Flex } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useI18next } from "gatsby-plugin-react-i18next";
import ProductCard from "../../components/productcard";
import LeftButton from "../../assets/arrow.svg";
import RightButton from "../../assets/right.svg";

const w = typeof window !== "undefined" && window.innerWidth;

const RecentPosts = ({ posts, data }) => {
  const { language } = useI18next();
  return (
    <Box position="relative">
      {language === "hy" && (
        <>
          <Container
            px="1.875rem"
            pt={{ base: "20", sm: "7.25rem" }}
            maxW="7xl"
            w="full"
            mb={{ base: "20", sm: "9.875rem" }}
          >
            <Stack
              position="relative"
              zIndex="5"
              w="full"
              direction={{ base: "column", md: "row" }}
              spacing="7"
              mb={{ base: "20", sm: "11.43rem" }}
            >
              <Box as="span" maxW="23.25rem" w="full" pr="4.5rem">
                <Heading fontFamily="Mardoto" as="h2" mb="1rem" fontSize="28px">
                  Վերջին հրապարակումներ
                </Heading>
                <Flex justifyContent="flex-start">
                  <Box className="left" p="0" mr="9" as={Button}>
                    <LeftButton />
                  </Box>
                  <Box className="right" p="0" ml="9" as={Button}>
                    <RightButton />
                  </Box>
                </Flex>
              </Box>
              <Swiper
                modules={[Navigation]}
                navigation={{
                  nextEl: ".right",
                  prevEl: ".left",
                }}
                spaceBetween={28}
                slidesPerView={w < 481 ? 1 : 2}
                scrollbar={{ draggable: true }}
                style={{ width: "100%" }}
              >
                {posts.slice(0, 3)?.map((post, idx) => (
                  <SwiperSlide
                    key={`post-${post.title}-${idx}`}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <ProductCard
                      src={post.image}
                      urls="recent-publications"
                      id={post.id}
                      shadow="none"
                      date
                      cardDate={post.date}
                      description={post.short_description}
                      title={post.title}
                      product
                      empty
                      moreButton
                      borderRadius="24px"
                      buttonText={data.moreButton}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Stack>
            {data.announcements?.length > 0 && (
              <>
                <Heading
                  fontFamily="Mardoto"
                  as="h2"
                  mb={{ base: "10", sm: "3.125rem" }}
                  fontSize="28px"
                  textAlign="center"
                >
                  {data.announcements1}
                </Heading>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction={{ base: "column", md: "row" }}
                  spacing="7"
                >
                  {data.announcements?.slice(0, 3).map((announcement, idx) => (
                    <Box
                      flex="1"
                      maxW="400px"
                      key={`announcements-${announcement.title}-${idx}`}
                    >
                      <ProductCard
                        id={announcement.id}
                        urls="announcements"
                        image={{ file: { url: announcement.image } }}
                        product
                        moreButton
                        empty
                        borderRadius="24px"
                        cardNumber={idx}
                        cardDate={announcement.date}
                        date
                        title={announcement.title}
                        description={announcement.description}
                        buttonText={data.moreButton}
                      />
                    </Box>
                  ))}
                </Stack>
              </>
            )}
          </Container>
          <Box
            zIndex="1"
            bg="#FBFBFB"
            position="absolute"
            top="0"
            left="0"
            h="555px"
            w="full"
            maxW="506px"
          />
        </>
      )}
    </Box>
  );
};
export default RecentPosts;

import React from "react";
import { Box, Stack, Heading, Container } from "@chakra-ui/react";
import ProductCard from "../../components/productcard";

const YourSteps = ({ data, title }) => {
  return (
    <Container px="1.875rem" maxW="7xl" mb={{base: '14', md: "9.875rem"}}>
      <Heading
      fontFamily="Mardoto"
      as="h2"
      mb="1rem"
      fontSize={{base: '2xl', sm: "28px"}}
      textAlign="center">
        {title}
      </Heading>
      <Heading
        fontFamily="Mardoto"
        as="h2"
        mb="4rem"
        fontSize={{base: '2xl', sm: "28px"}}
        textAlign="center"
        color="grey.100"
      >
        {data.subTitle}
      </Heading>

      <Stack
        justifyContent="center"
        alignItems="center"
        direction={{ base: "column", md: "row" }}
        spacing={{base: "14", md: '7'}}>
          {data.yourSteps?.map((yourStep,idx) =>  (
              <Box flex="1" maxW="400px" key={`yourStep-${yourStep.title}-${idx}`}>
                  <ProductCard
                      image={{file: {url: yourStep.image}}}
                      product
                      borderRadius="24px 24px 24px 0"
                      mainButton={yourStep.mainButton}
                      mainButtonLink={yourStep.mainButtonLink}
                      title={yourStep.title}
                      description={yourStep.description}
                  />
              </Box>
          ))}
      </Stack>
    </Container>
  );
};
export default YourSteps;

import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicServices = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicServices {
     allPrismicServices(filter: {tags: {in: "services"}}) {
        nodes {
          lang
          data {
          central_videothumbnail {
              url
            }
            credit_videothumbnail {
              url
            }
            deposit_videothumbnail {
              url
            }
            investment_videothumbnail {
              url
            }
            payment_videothumbnail {
              url
            }
            pledge_videothumbnail {
              url
            }
            transfer_videothumbnail {
              url
            }
            videothumbnail {
              url
            }
            loan_videothumbnail {
              url
            }
            currency_videothumbnail {
              url
            }
            central_your_step_title {
              text
            }
            credit_history_your_step_title {
              text
            }
            currency_your_step_title {
              text
            }
            deposit_your_step_title {
              text
            }
            insurance_your_step_title {
              text
            }
            investment_your_step_title {
              text
            }
            loan_your_step_title {
              text
            }
            payment_card_your_step_title {
              text
            }
            pledge_your_step_title {
              text
            }
            transfer_your_step_title {
              text
            }
            aboutlist {
              richText
            }
            aboutlistcentral {
              richText
            }
            aboutlistcredit {
              richText
            }
            aboutlistcurrency {
              richText
            }
            aboutlistdeposit {
              richText
            }
            aboutlistinvestment {
              richText
            }
            aboutlistloan {
              richText
            }
            aboutlistpaymentcard {
              richText
            }
            aboutlistpledge {
              richText
            }
            aboutlisttransfer {
              richText
            }
            below {
              richText
            }
            belowcentral {
              richText
            }
            belowcredit {
              richText
            }
            belowcurrency {
              richText
            }
            belowdeposit {
              richText
            }
            belowinvestment {
              richText
            }
            belowloan {
              richText
            }
            belowpaymentcard {
              richText
            }
            belowpledge {
              richText
            }
            belowtransfer {
              richText
            }
            firstimage {
              url
            }
            firstimagecentral {
              url
            }
            firstimagecredit {
              url
            }
            firstimagecurrency {
              url
            }
            firstimagedeposit {
              url
            }
            firstimageinvestment {
              url
            }
            firstimageloan {
              url
            }
            firstimagepaymentcard {
              url
            }
            firstimagepledge {
              url
            }
            firstimagetransfer {
              url
            }
            header {
              text
            }
            headercentral {
              text
            }
            headercurrency {
              text
            }
            headerdeposit {
              text
            }
            headerinvestment {
              text
            }
            headerloan {
              text
            }
            headerpaymentcard {
              text
            }
            headerpledge {
              text
            }
            headertransfer {
              text
            }
            headrcredit {
              text
            }
            icon {
              url
            }
            iconcentral {
              url
            }
            iconcredit {
              url
            }
            iconcurrency {
              url
            }
            icondeposit {
              url
            }
            iconinvestment {
              url
            }
            iconloan {
              url
            }
            iconpaymentcard {
              url
            }
            iconpledge {
              url
            }
            icontransfer {
              url
            }
            link
            linkcentral
            linkcredit
            linkcurrency
            linkdeposit
            linkinvestment
            linkloan
            linkpaymentcard
            linkpledge
            linktransfer
            list {
              service
            }
            listcentral {
              service
            }
            listcredit {
              service
            }
            listcurrency {
              service
            }
            listdeposit {
              service
            }
            listinvestment {
              service
            }
            listloan {
              service
            }
            listpaymentcard {
              service
            }
            listpledge {
              service
            }
            listtransfer {
              service
            }
            secondimage {
              url
            }
            secondimagecentral {
              url
            }
            secondimagecredit {
              url
            }
            secondimagecurrency {
              url
            }
            secondimagedeposit {
              url
            }
            secondimageinvestment {
              url
            }
            secondimageloan {
              url
            }
            secondimagepaymentcard {
              url
            }
            secondimagepledge {
              url
            }
            secondimagetransfer {
              url
            }
            subtitle {
              richText
            }
            subtitlecentral {
              richText
            }
            subtitlecredit {
              richText
            }
            subtitlecurrency {
              richText
            }
            subtitledeposit {
              richText
            }
            subtitleinvestment {
              richText
            }
            subtitleloan {
              richText
            }
            subtitlepaymentcard {
              richText
            }
            subtitlepledge {
              richText
            }
            subtitletransfer {
              richText
            }
            thirdvideolink {
              url
            }
            thirdvideolinkcentral {
              url
            }
            thirdvideolinkcredit {
              url
            }
            thirdvideolinkcurrency {
              url
            }
            thirdvideolinkdeposit {
              url
            }
            thirdvideolinkinvestment {
              url
            }
            thirdvideolinkloan {
              url
            }
            thirdvideolinkpaymentcard {
              url
            }
            thirdvideolinkpledge {
              url
            }
            thirdvideolinktransfer {
              url
            }
            title {
              text
            }
            title2 {
              text
            }
            titlecentral1 {
              text
            }
            titlecredit1 {
              text
            }
            titlecurrency1 {
              text
            }
            titledeposit1 {
              text
            }
            titleinvestment1 {
              text
            }
            titleloan1 {
              text
            }
            titlepaymentcard1 {
              text
            }
            titlepledge1 {
              text
            }
            titletransfer1 {
              text
            }
            whatyoudo {
              richText
            }
            whatyoudocentral {
              richText
            }
            whatyoudocredit {
              richText
            }
            whatyoudocurrency {
              richText
            }
            whatyoudodeposit {
              richText
            }
            whatyoudoinvestment {
              richText
            }
            whatyoudoloan {
              richText
            }
            whatyoudopaymentcard {
              richText
            }
            whatyoudopledge {
              richText
            }
            whatyoudotransfer {
              richText
            }
            body {
              ... on PrismicServicesDataBodyUsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body1 {
              ... on PrismicServicesDataBody1UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body2 {
              ... on PrismicServicesDataBody2UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body3 {
              ... on PrismicServicesDataBody3UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body4 {
              ... on PrismicServicesDataBody4UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body5 {
              ... on PrismicServicesDataBody5UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body6 {
              ... on PrismicServicesDataBody6UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body7 {
              ... on PrismicServicesDataBody7UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body8 {
              ... on PrismicServicesDataBody8UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
            body9 {
              ... on PrismicServicesDataBody9UsefulLinks {
                id
                slice_type
                primary {
                  usefullinktitle {
                    text
                  }
                  description {
                    richText
                  }
                }
                items {
                  linktitle
                  href {
                    url
                  }
                }
              }
            }
          }
        }
      }
      }
  `);
    return sanitize(response.allPrismicServices.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        homePage: {
            title: response.title.text,
            services: [
                {
                    header: response.header.text,
                    link: response.link,
                    icon: response.icon.url
                },
                {
                    header: response.headerloan.text,
                    link: response.linkloan,
                    icon: response.iconloan.url
                },
                {
                    header: response.headerdeposit.text,
                    link: response.linkdeposit,
                    icon: response.icondeposit.url
                },
                {
                    header: response.headerpaymentcard.text,
                    link: response.linkpaymentcard,
                    icon: response.iconpaymentcard.url
                },
                {
                    header: response.headertransfer.text,
                    link: response.linktransfer,
                    icon: response.icontransfer.url
                },
                {
                    header: response.headerpledge.text,
                    link: response.linkpledge,
                    icon: response.iconpledge.url
                },
                {
                    header: response.headercurrency.text,
                    link: response.linkcurrency,
                    icon: response.iconcurrency.url
                },
                {
                    header: response.headrcredit.text,
                    link: response.linkcredit,
                    icon: response.iconcredit.url
                },
                {
                    header: response.headercentral.text,
                    link: response.linkcentral,
                    icon: response.iconcentral.url
                },
                {
                    header: response.headerinvestment.text,
                    link: response.linkinvestment,
                    icon: response.iconinvestment.url
                },
            ]
        },
        insurance: {
            header: response.header.text,
            title: response.title2.text,
            subTitle: response.subtitle.richText,
            firstImage: response.firstimage.url,
            below: response.below.richText,
            secondImage: response.secondimage.url,
            thirdVideo: response.thirdvideolink.url,
            videoThumbnail: response.videothumbnail.url,
            aboutList: response.aboutlist.richText,
            whatYouDo: response.whatyoudo.richText,
            lists: response.list.map(item => item.service),
            insuranceYourStepTitle: response.insurance_your_step_title.text,
            usefulLinks: response.body.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        loan: {
            header: response.headerloan.text,
            title: response.titleloan1.text,
            subTitle: response.subtitleloan.richText,
            firstImage: response.firstimageloan.url,
            below: response.belowloan.richText,
            secondImage: response.secondimageloan.url,
            thirdVideo: response.thirdvideolinkloan.url,
            videoThumbnail: response.loan_videothumbnail.url,
            aboutList: response.aboutlistloan.richText,
            whatYouDo: response.whatyoudoloan.richText,
            lists: response.listloan.map(item => item.service),
            loanYourStepTitle: response.loan_your_step_title.text,
            usefulLinks: response.body1.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        depositBankAccount: {
            header: response.headerdeposit.text,
            title: response.titledeposit1.text,
            subTitle: response.subtitledeposit.richText,
            firstImage: response.firstimagedeposit.url,
            below: response.belowdeposit.richText,
            secondImage: response.secondimagedeposit.url,
            thirdVideo: response.thirdvideolinkdeposit.url,
            videoThumbnail: response.deposit_videothumbnail.url,
            aboutList: response.aboutlistdeposit.richText,
            whatYouDo: response.whatyoudodeposit.richText,
            lists: response.listdeposit.map(item => item.service),
            depositYourStepTitle: response.deposit_your_step_title.text,
            usefulLinks: response.body2.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        paymentCard: {
            header: response.headerpaymentcard.text,
            title: response.titlepaymentcard1.text,
            subTitle: response.subtitlepaymentcard.richText,
            firstImage: response.firstimagepaymentcard.url,
            below: response.belowpaymentcard.richText,
            secondImage: response.secondimagepaymentcard.url,
            thirdVideo: response.thirdvideolinkpaymentcard.url,
            videoThumbnail: response.payment_videothumbnail.url,
            aboutList: response.aboutlistpaymentcard.richText,
            whatYouDo: response.whatyoudopaymentcard.richText,
            lists: response.listpaymentcard.map(item => item.service),
            paymentCardYourStepTitle: response.payment_card_your_step_title.text,
            usefulLinks: response.body3.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        transfer: {
            header: response.headertransfer.text,
            title: response.titletransfer1.text,
            subTitle: response.subtitletransfer.richText,
            firstImage: response.firstimagetransfer.url,
            below: response.belowtransfer.richText,
            secondImage: response.secondimagetransfer.url,
            thirdVideo: response.thirdvideolinktransfer.url,
            videoThumbnail: response.transfer_videothumbnail.url,
            aboutList: response.aboutlisttransfer.richText,
            whatYouDo: response.whatyoudotransfer.richText,
            lists: response.listtransfer.map(item => item.service),
            transferYourStepTitle: response.transfer_your_step_title.text,
            usefulLinks: response.body4.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        pledge: {
            header: response.headerpledge.text,
            title: response.titlepledge1.text,
            subTitle: response.subtitlepledge.richText,
            firstImage: response.firstimagepledge.url,
            below: response.belowpledge.richText,
            secondImage: response.secondimagepledge.url,
            thirdVideo: response.thirdvideolinkpledge.url,
            videoThumbnail: response.pledge_videothumbnail.url,
            aboutList: response.aboutlistpledge.richText,
            whatYouDo: response.whatyoudopledge.richText,
            lists: response.listpledge.map(item => item.service),
            pledgeYourStepTitle: response.pledge_your_step_title.text,
            usefulLinks: response.body5.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        currencyExchange: {
            header: response.headercurrency.text,
            title: response.titlecurrency1.text,
            subTitle: response.subtitlecurrency.richText,
            firstImage: response.firstimagecurrency.url,
            below: response.belowcurrency.richText,
            secondImage: response.secondimagecurrency.url,
            thirdVideo: response.thirdvideolinkcurrency.url,
            videoThumbnail: response.currency_videothumbnail.url,
            aboutList: response.aboutlistcurrency.richText,
            whatYouDo: response.whatyoudocurrency.richText,
            lists: response.listcurrency.map(item => item.service),
            currencyExchangeYourStepTitle: response.currency_your_step_title.text,
            usefulLinks: response.body6.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        creditHistory: {
            header: response.headrcredit.text,
            title: response.titlecredit1.text,
            subTitle: response.subtitlecredit.richText,
            firstImage: response.firstimagecredit.url,
            below: response.belowcredit.richText,
            secondImage: response.secondimagecredit.url,
            thirdVideo: response.thirdvideolinkcredit.url,
            videoThumbnail: response.credit_videothumbnail.url,
            aboutList: response.aboutlistcredit.richText,
            whatYouDo: response.whatyoudocredit.richText,
            lists: response.listcredit.map(item => item.service),
            creditHistoryYourStepTitle: response.credit_history_your_step_title.text,
            usefulLinks: response.body7.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        centralDepository: {
            header: response.headercentral.text,
            title: response.titlecentral1.text,
            subTitle: response.subtitlecentral.richText,
            firstImage: response.firstimagecentral.url,
            below: response.belowcentral.richText,
            secondImage: response.secondimagecentral.url,
            thirdVideo: response.thirdvideolinkcentral.url,
            videoThumbnail: response.central_videothumbnail.url,
            aboutList: response.aboutlistcentral.richText,
            whatYouDo: response.whatyoudocentral.richText,
            lists: response.listcentral.map(item => item.service),
            centralDepositoryYourStepTitle: response.central_your_step_title.text,
            usefulLinks: response.body8.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
        investmentCompanyAndFund: {
            header: response.headerinvestment.text,
            title: response.titleinvestment1.text,
            subTitle: response.subtitleinvestment.richText,
            firstImage: response.firstimageinvestment.url,
            below: response.belowinvestment.richText,
            secondImage: response.secondimageinvestment.url,
            thirdVideo: response.thirdvideolinkinvestment.url,
            videoThumbnail: response.investment_videothumbnail.url,
            aboutList: response.aboutlistinvestment.richText,
            whatYouDo: response.whatyoudoinvestment.richText,
            lists: response.listinvestment.map(item => item.service),
            investmentCompanyAndFundYourStepTitle: response.investment_your_step_title.text,
            usefulLinks: response.body9.map(item => ({
                id: item.id,
                title: item.primary.usefullinktitle.text,
                description: item.primary.description.richText,
                links: item.items.map(link => ({
                    title: link.linktitle,
                    href: link.href.url
                }))
            }))
        },
    }
}
export default usePrismicServices;
import React from "react";
import { Box, Flex, Text, Heading, Button, Image } from "@chakra-ui/react";
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import lines from '../../images/des_element.png'
import RichText from "../PrismicRichText";

const FindOut = ({ data }) => {
  const {language} = useI18next()
  return (
    <Flex
      w="full"
      pt={{base: '10', md: 0}}
      direction={{ base: "column-reverse", md: "row" }}
      mb={language !== 'hy' && {base: '10'}}
      bg="green.200"
      position="relative"
    >
      <Image
        position="absolute"
        right="0"
        w="600px"
        zIndex="5"
        bottom="0"
        src={lines}/>
      <Box
        mr={{base: '', sm: '6rem'}}
        w={{base: '100%', md: "50%"}}
        pt={{base: '12', sm: "5.25rem"}}
        >
        <Image
          objectPosition="top"
          position="relative"
          zIndex="9"
          borderRadius="0 24px 0 0"
          width={{base: '315px', sm: "100%"}}
          objectFit="cover"
          height={{base: '271px', sm: "493px"}}
          src={data.findOutImage}
          />
      </Box>
      <Flex
        zIndex="9"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          maxW="31.25rem"
          mx={{base:"1.875rem"}}>
          <Heading
            fontFamily="Mardoto"
            as="h2"
            color="White"
            fontSize={{base: '2xl', sm: "28px"}}
            mb="1rem">
            {data.findOutTitle}
          </Heading>
          <Box
            fontFamily="Mardoto"
            fontSize={{base: 'md', sm: "lg"}}
            color="white"
            opacity="0.6"
            mb="2.25rem">
            <RichText text={data.findOutDescription}/>
          </Box>
          <Button as={Link} to="/submit-claim?#find-out" variant="green" w="200px" color="white">
            {data.findOutButtonText}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
export default FindOut;

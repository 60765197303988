import * as React from "react";
import { Box } from "@chakra-ui/react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Seo from "../components/SEO";
import Main from "../components/layouts/Main";
import MainSwiper from "../components/mainSwiper";
import Home from "../components/homePage/Home";
import Pics from "../components/homePage/Pics";
import YourSteps from "../components/homePage/YourSteps";
import FindOut from "../components/homePage/FindOut";
import RecentPosts from "../components/homePage/RecentPosts";
import usePrismicHomepage from "../hooks/usePrismicHomepage";
import usePrismicNews from "../hooks/usePrismicNews";
import usePrismicServices from "../hooks/usePrismicServices";
import usePrismicYourSteps from "../hooks/usePrismicYourSteps";
const IndexPage = () => {
  const { language, t } = useTranslation();
  const data = usePrismicHomepage();
  const news = usePrismicNews();
  const yourSteps = usePrismicYourSteps();
  const { homePage } = usePrismicServices();
  return (
    <Main>
      <Seo
        title={t("Home")}
        link={`https://www.fsm.am/${language}/transfers`}
      />
      <Box w="full">
        <MainSwiper slides={data.slides} />
        <Box height="6px" bg="green.200" w="full" />
      </Box>
      <Home data={homePage} />
      <Pics data={data} />
      <YourSteps data={yourSteps} title={data.yourStepTitle} />
      <FindOut data={data} />
      <RecentPosts posts={news.news} data={data} />
    </Main>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
